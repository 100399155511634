import React, { useEffect, useRef } from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  useLocation,
} from "react-router-dom";
import MainMenu from "./Components/MainMenu";
import { AnimatePresence } from "framer-motion";
import FrinoLogo from "./Components/FrinoLogo";
import FrinoShows from "./Components/FrinoShows";
import MouseController from "./Components/MouseController";
import PixiManager from "./Components/PixiManager";
import WtfIsFrino from "./Components/WtfIsFrino";
import Mail from "./Components/Mail";
import useStore from "./Store";
import ThankYou from "./Components/ThankYou";
import LanguageSelect from "./Components/LanguageSelect";
import Opteffen from "./Components/Opteffen";

/*
const FrinoPlayerContainer = ReactLazyPreload(
  () => import("./Components/FrinoPlayerContainer")
);
*/
function App() {
  const timerRef = useRef<any>();
  /*
  useEffect(() => {
    FrinoPlayerContainer.preload();
    return () => {};
  }, []);
  */
  const showMailPopup = useStore((state) => state.showMailPopup);
  const showThankYou = useStore((state) => state.showThankYou);

  const setShowMailPopup = useStore((state) => state.setShowMailPopup);

  const location = useLocation();

  function showPopup() {
    setShowMailPopup(true);
  }

  useEffect(() => {
    timerRef.current = setTimeout(showPopup, 5000);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);
  return (
    <div className="w-screen h-screen relative">
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <MainMenu />
            <AnimatePresence>
              {showMailPopup && <Mail />}
              {showThankYou && <ThankYou />}
            </AnimatePresence>
          </Route>

          <Route path="/tetsen-krijgen">
            <FrinoShows />
          </Route>
          <Route path="/wtf-is-frino">
            <WtfIsFrino />
          </Route>
          <Route path="/opteffen">
            <Opteffen />
          </Route>
        </Switch>
      </AnimatePresence>
      <LanguageSelect />
      <PixiManager />

      {window.innerWidth > 500 && <MouseController />}
    </div>
  );
}

export default App;
