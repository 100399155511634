import React, { useEffect, useState, useRef } from "react";

type animationProps = {
  active: boolean;
  speed: number;
  imgArr: string[];
};

const AnimatedImg = React.memo(function AnimatedImg({
  active,
  speed,
  imgArr,
}: animationProps) {
  const [currentImg, setCurrentImg] = useState(0);
  const imgRef = useRef(0);
  const timeoutRef = useRef<any>();
  const updateAnim = () => {
    imgRef.current++;
    if (imgRef.current >= imgArr.length) {
      imgRef.current = 0;
    }
    setCurrentImg(imgRef.current);
    if (active) {
      timeoutRef.current = setTimeout(updateAnim, speed);
    }
  };

  useEffect(() => {
    if (active) {
      timeoutRef.current = setTimeout(updateAnim, speed);
    }
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [active, speed, updateAnim]);

  return (
    <img
      className="absolute w-full left-0 top-0"
      src={active ? imgArr[currentImg] : imgArr[0]}
    />
  );
});

export default AnimatedImg;
