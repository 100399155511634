import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeAnim, verticalAnim } from "../Animations";
import useStore from "../Store";
import AnimatedImg from "./AnimatedImg";
import SocialButtons from "./SocialButtons";

export default function Opteffen() {
  const lang = useStore((state) => state.lang);
  const [email, setEmail] = useState("");
  const emailRef = useRef("");
  const [submitState, setSubmitState] = useState<"open" | "complete" | "error">(
    "complete"
  );
  const formRef = useRef<HTMLFormElement>(null);

  function updateEmail(e: any) {
    setEmail(e.target.value);
    emailRef.current = e.target.value;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formRef.current) {
      const formData = new FormData(formRef.current);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //@ts-ignore
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log("Form successfully submitted");
          setSubmitState("complete");
        })
        .catch((error) => {
          console.log(error);
          setSubmitState("error");
        });
    }
  };

  return (
    <div
      className="w-screen h-screen bg-cover absolute left-0 z-0 text-white bg-black text-center overflow-hidden "
      style={{
        cursor: "none",
      }}
    >
      <div
        className="w-screen h-screen bg-cover absolute left-0 z-0text-center overflow-hidden opacity-50"
        style={{
          cursor: "none",
          backgroundImage: "url('/img/bg1.jpg')",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        {submitState === "open" && (
          <>
            <h1 className="w-screen px-2 sm:mt-0">
              {lang === "br"
                ? "Vond gij die muziek van 'Opteffen' ok keivet?"
                : "De muziek van 'Opteffen' thuis nog eens terugluisteren?"}
            </h1>
            <h3 className="sm:mt-4 mt-4 block spaced mb-4">
              {lang === "br"
                ? "Vult hier oew email in, den janken we die nummers gelijk oew mailbox in!"
                : "Vul hieronder je emailadres in, dan sturen we onze nummers meteen naar je toe!"}
            </h3>
            <form
              className="flex flex-col items-center"
              onSubmit={handleSubmit}
              name="emailform"
              data-netlify="true"
              ref={formRef}
            >
              <input type="hidden" name="form-name" value="emailform" />

              <input
                name="email"
                type="email"
                required
                className="p-2 w-80 text-black"
                value={email}
                onChange={updateEmail}
              ></input>
              <div className="flex items-start justify-center"></div>
              <button
                type="submit"
                className="font-frino border-white border-2 p-2 rounded-md block text-xl mt-2 spaced"
              >
                {lang === "br" ? "Kom maar door!" : "Verstuur"}
              </button>
            </form>
          </>
        )}
        {submitState === "complete" && (
          <>
            <h1 className="w-screen px-2 sm:mt-0 -mt-10">
              {lang === "br"
                ? "Ge zeit keibedankt!"
                : "Bedankt, het is gelukt!"}
            </h1>
            <h3 className="sm:mt-4 mt-4 block spaced mb-4">
              {lang === "br"
                ? "Ge heurt binnekort van ons..."
                : "Je hoort snel van ons..."}
            </h3>
            <a
              className="font-frino text-2xl underline"
              href="https://open.spotify.com/artist/2sN8nlycU05xL8VbmCQXcK?si=NMyHHYNBTMSvPP9KxAspyw"
              target="_blank"
            >
              Luister verder op spotify
            </a>
            <div className="font-frino">of</div>
            <a
              className="font-frino text-2xl underline"
              href="https://www.instagram.com/frino_brabohop"
              target="_blank"
            >
              Volg ons op instagram
            </a>
          </>
        )}
        {submitState === "error" && (
          <>
            <h1 className="w-screen px-2 sm:mt-0">
              {lang === "br"
                ? "Godverpielekes, der ging iets mis"
                : "Oeps, er ging iets mis"}
            </h1>
            <h3 className="sm:mt-4 mt-4 block spaced mb-4">
              {lang === "br"
                ? "Probeer het later nog unne keer"
                : "Probeer het later nog eens"}
            </h3>
          </>
        )}
      </div>
      <img
        src="/img/koenOpteffen.png"
        className="sm:w-40 w-24 absolute -bottom-8 -right-3   origin-bottom-right z-20"
      />
      <img
        src="/img/rubenOpteffen.png"
        className="sm:w-44 w-28 absolute -bottom-8 -left-8   origin-bottom-left z-40"
      />
      <img
        src="/img/vlaggetjes.png"
        className="sm:h-52 h-20 absolute sm:-top-4 top-0 -left-24  z-0 transform -rotate-45"
      />
    </div>
  );
}
