import React from "react";
import { Link } from "react-router-dom";
import useStore from "../Store";

export default function NavLinks() {
  const setPointer = useStore((state) => state.setPointer);
  const lang = useStore((state) => state.lang);

  return (
    <div
      className="absolute top-4 left-20 sm:left-28 z-20"
      onMouseEnter={() => setPointer(true)}
      onMouseLeave={() => setPointer(false)}
    >
      <Link to="/" className="">
        <span className="inline-block sm:w-96 sm:text-2xl w-32 link transform sm:hover:scale-105 active:scale-110">
          {lang === "br"
            ? "Kwil toch ger wa anders"
            : "Terug naar het hoofdmenu"}
        </span>
      </Link>
    </div>
  );
}
