import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeAnim, verticalAnim } from "../Animations";
import useStore from "../Store";
import AnimatedImg from "./AnimatedImg";
import SocialButtons from "./SocialButtons";

export default function MainMenu() {
  const setPointer = useStore((state) => state.setPointer);
  const setShowMailPopup = useStore((state) => state.setShowMailPopup);
  const setHasShownMailPopup = useStore((state) => state.setHasShownMailPopup);
  const lang = useStore((state) => state.lang);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={fadeAnim}
      transition={{ duration: 0.6 }}
      className="w-screen h-screen bg-cover absolute left-0 z-0 text-black text-center overflow-hidden"
      style={{
        cursor: "none",
      }}
    >
      <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        <h1 className="w-screen px-2 mb-8 -mt-12 sm:mt-0">
          {lang === "br" ? "Wa wilde doen?" : "Maak een keuze:"}
        </h1>

        <a
          href="https://open.spotify.com/artist/2sN8nlycU05xL8VbmCQXcK?si=5LpinbP_Sz24Ipp2yPlLmg"
          target="_blank"
          className=""
        >
          <h3
            className="mb-4 transform sm:hover:scale-110"
            onMouseEnter={() => setPointer(true)}
            onMouseLeave={() => setPointer(false)}
          >
            {lang === "br" ? "Gezever Luistere" : "Muziek"}
          </h3>
        </a>
        <a
          href="https://www.youtube.com/@frinouiteindj6233"
          target="_blank"
          className=" sm:mt-10"
        >
          <h3
            className="mb-4 transform sm:hover:scale-110"
            onMouseEnter={() => setPointer(true)}
            onMouseLeave={() => setPointer(false)}
          >
            {lang === "br" ? "Brabo's Kieke" : "Video's"}
          </h3>
        </a>
        <a href="https://frinogame.netlify.app/" target="_blank">
          <h3
            className="mb-4 transform sm:hover:scale-110 sm:mt-10"
            onMouseEnter={() => setPointer(true)}
            onMouseLeave={() => setPointer(false)}
          >
            {lang === "br" ? "Spelleke speule" : "De Frino-game spelen"}
          </h3>
        </a>
        <Link to="/tetsen-krijgen" className=" sm:mt-10">
          <h3
            className="mb-4 transform sm:hover:scale-110"
            onMouseEnter={() => setPointer(true)}
            onMouseLeave={() => setPointer(false)}
          >
            {lang === "br" ? "Tetsen Krijge" : "Shows"}
          </h3>
        </Link>
        <div
          className=" sm:mt-10"
          onClick={() => {
            setShowMailPopup(true);
            setHasShownMailPopup(true);
          }}
        >
          <h3
            className="mb-4 transform sm:hover:scale-110 "
            onMouseEnter={() => setPointer(true)}
            onMouseLeave={() => setPointer(false)}
          >
            {lang === "br" ? "Meel Vange" : "Houd mij op de hoogte!"}
          </h3>
        </div>
        <a
          className="transform sm:hover:scale-110 sm:mt-10"
          href="mailto:info@frino.nl"
          onMouseEnter={() => setPointer(true)}
          onMouseLeave={() => setPointer(false)}
        >
          <h3>
            {lang === "br" ? "Ons 'ne Email sture" : "Contact met ons opnemen"}
          </h3>
          <img
            src="/img/mail.png"
            className="sm:w-20 sm:h-20 w-12 h-12  inline transform duration-500 transition-transform sm:hover:rotate-180"
          />
        </a>
      </div>
      <Link to="/wtf-is-frino">
        <h4 className="absolute top-4 right-4 z-50 flex items-center transform sm:hover:scale-110">
          {lang === "br" || window.innerWidth < 530
            ? "WTF IS"
            : "Vertel meer over"}
          <img src={"/img/frinoLogo.png"} className="md:w-24 w-16 mx-2 z-50" />
          {lang === "br" || window.innerWidth < 530 ? "?!" : "alsjeblieft!"}
        </h4>
      </Link>
      <img
        src="/img/koenuitgeknipt.png"
        className="sm:w-52 w-24 absolute -bottom-8 -right-3 rotateAnim rotateAnim1 origin-bottom-right z-20"
      />
      <img
        src="/img/rubenuitgeknipt.png"
        className="sm:w-56 w-24 absolute -bottom-8 -left-2 rotateAnim rotateAnim2 origin-bottom-left z-40"
      />
      <img
        src="/img/verrekijker.png"
        className="sm:w-80 w-36 absolute bottom-10 sm:bottom-0 right-6 sm:right-20 verrekijkerAnim  z-10"
      />
      <img
        src="/img/brabantvlag.png"
        className="sm:w-96 w-40 absolute bottom-14 sm:bottom-64  right-0 z-0 sm:opacity-100 opacity-40"
        style={{ transform: "rotate(90deg) rotateX(180deg)" }}
      />
      <img
        src="/img/evoluon.png"
        className="sm:w-96 w-44 absolute sm:-bottom-4 -bottom-1 sm:right-20 right-4  z-10"
      />
      <img
        src="/img/vlaggetjes.png"
        className="sm:h-52 h-20 absolute sm:-top-4 top-0 -left-24  z-0 transform -rotate-45"
      />

      <div className="absolute -left-4 w-56 sm:w-80 sm:left-32 bottom-40 sm:bottom-60 z-20">
        <AnimatedImg
          active
          speed={500}
          imgArr={[
            "/img/tijger.png",
            "/img/tijger.png",
            "/img/tijger.png",
            "/img/tijger.png",
            "/img/tijgerknipper.png",
            "/img/tijger.png",
            "/img/tijgerknipper.png",
          ]}
        />
      </div>
    </motion.div>
  );
}
