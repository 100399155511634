import React from "react";
import { circleAnim, verticalAnim, verticalAnimTop } from "../Animations";
import FrinoLogo from "./FrinoLogo";
import NavLinks from "./NavLinks";
import { motion, AnimatePresence } from "framer-motion";
import useStore from "../Store";
import SocialButtons from "./SocialButtons";

export default function WtfIsFrino() {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={circleAnim}
      transition={{ duration: 0.6 }}
      className="w-screen bg-cover relative z-10 overflow-hidden min-h-screen"
      style={{
        backgroundImage: `url('/img/panterprint.png')`,
      }}
    >
      <div className="absolute w-full h-full inset-0 bg-white bg-opacity-75 z-0"></div>

      <div className="mx-auto relative pt-32 sm:pb-8 pb-4 z-10 flex flex-col items-center">
        <h2 className="sm:text-6xl">WTF Is Frino?</h2>
      </div>

      <span className="z-10 relative text-center block mt-4 mb-10 sm:w-2/3 md:w-1/2 xl:w-1/3 mx-auto spaced px-2">
        <motion.p
          initial="initial"
          animate="in"
          variants={verticalAnim}
          transition={{ duration: 0.6, delay: 0, type: "spring" }}
        >
          Leve de onderhoudsarme tuin; met een fles Bav in de hand behandelt
          Frino vanuit een rieten stoel tussen de hardplastic tuinkabouters de
          grindtegelfilosofie des levens. Zelf noemt het duo het Brabohop: een
          kant die je nog maar weinig in het Nederlandse muzieklandschap
          terugziet.
        </motion.p>

        <motion.p
          className="mt-8"
          initial="initial"
          animate="in"
          variants={verticalAnim}
          transition={{ duration: 0.6, delay: 1, type: "spring" }}
        >
          Daarom nemen bruiloftmuzikant Ruben den Brok en vuilnisman Koen Frijns
          je mee in hun Fiat Punto door de straten van Eindhoven, om in de geest
          en woorden van de kreupele krijgers van Woensel en de
          excentriekelingen uit het centrum (en ver daarbuiten!) je perspectief
          paradijselijk te verrijken. Beats, Brabants en brullen.
        </motion.p>

        <motion.h4
          className="mt-4"
          initial="initial"
          animate="in"
          variants={verticalAnim}
          transition={{ duration: 0.6, delay: 2, type: "spring" }}
        >
          Skon, toch?
        </motion.h4>
        <motion.div
          initial="initial"
          animate="in"
          variants={verticalAnim}
          transition={{ duration: 0.6, delay: 3, type: "spring" }}
          className="sm:w-72 w-40 mx-auto mt-4"
        >
          <SocialButtons />
        </motion.div>
      </span>
      <NavLinks />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={verticalAnimTop}
        transition={{ duration: 0.6, delay: 2, type: "spring" }}
        className="absolute w-screen h-full "
      >
        <img
          src="/img/teacher.png"
          className="w-40 md:w-96 xl:w-120 bottom-0 absolute md:-right-10 xl:right-32 -right-4 opacity-75 md:opacity-100"
        ></img>
      </motion.div>
    </motion.div>
  );
}
