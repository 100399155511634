import create from "zustand";

interface State {
  currentPage: string;
  setCurrentPage: (val: string) => void;
  pointer: boolean;
  setPointer: (val: boolean) => void;
  showMailPopup: boolean;
  setShowMailPopup: (val: boolean) => void;
  showThankYou: boolean;
  setShowThankYou: (val: boolean) => void;
  hasShownMailPopup: boolean;
  setHasShownMailPopup: (val: boolean) => void;
  lang: string;
  setLang: (val: string) => void;
}

const useStore = create<State>((set) => ({
  currentPage: "",
  setCurrentPage: (val) => set((state) => ({ currentPage: val })),
  pointer: false,
  setPointer: (val) => set((state) => ({ pointer: val })),
  showMailPopup: false,
  setShowMailPopup: (val) => set((state) => ({ showMailPopup: val })),
  showThankYou: false,
  setShowThankYou: (val) => set((state) => ({ showThankYou: val })),
  hasShownMailPopup: false,
  setHasShownMailPopup: (val) => set((state) => ({ hasShownMailPopup: val })),
  lang: "br",
  setLang: (val) => set((state) => ({ lang: val })),
}));

export default useStore;
