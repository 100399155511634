import React, { useEffect, useState, useRef } from "react";
import useStore from "../Store";

export default function MouseController() {
  const [mousePos, setMousePos] = useState({ x: -100, y: -100 });
  const mousePosRef = useRef({ x: -100, y: -100 });
  const [rotationClass, setRotationClass] = useState("");
  const pointer = useStore((state) => state.pointer);

  const cursorOffsetX = 50;
  const cursorOffsetY = 30;
  const cursorTolerance = 4;

  const updateMousePos = (e: MouseEvent) => {
    /*
    if (
      Math.abs(e.clientX - cursorOffsetX - mousePosRef.current.x) >
      cursorTolerance
    ) {
      if (e.clientX - cursorOffsetX < mousePosRef.current.x) {
        setRotationClass("");
      } else {
        setRotationClass("");
      }
    }
    */
    mousePosRef.current = {
      x: e.clientX - cursorOffsetX,
      y: e.clientY - cursorOffsetY,
    };
    setMousePos({ x: e.clientX - cursorOffsetX, y: e.clientY - cursorOffsetY });
  };

  const mouseDown = () => {
    const mouse: any = document.getElementById("mouseImage");
  };

  const mouseUp = () => {
    const mouse: any = document.getElementById("mouseImage");
  };

  useEffect(() => {
    /*
    const iframes = document.querySelectorAll("iframe");

    iframes.forEach((element) => {
      console.log(element.contentDocument);
      element.contentDocument
        ?.querySelector("body")
        .addEventListener("mousemove", (e) => dispatchMouseEvent(e, element));
    });

    window.addEventListener("iframeMouseMoveMessage", (e) => {
      console.log(e);
    });

    console.log(iframes);
    */
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", updateMousePos);
      window.addEventListener("mousedown", mouseDown);
      window.addEventListener("mouseup", mouseUp);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("mousemove", updateMousePos);
        window.removeEventListener("mousedown", mouseDown);
        window.removeEventListener("mouseup", mouseUp);
      }
    };
  }, []);

  return (
    <div
      id="mouseImage"
      className={`fixed bg-contain transform pointer-events-none z-50 bg-no-repeat ${rotationClass}`}
      style={{
        backgroundImage: `url('${
          pointer ? "/img/vuilniswagenopen.png " : "/img/vuilniswagendicht.png"
        }')`,
        width: "100px",
        height: "50px",
        left: mousePos.x + "px",
        top: mousePos.y + "px",
        zIndex: 99999,
      }}
    ></div>
  );
}
