import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { horizontalAnim } from "../Animations";
import useStore from "../Store";

export default function Mail() {
  const setShowMailPopup = useStore((state) => state.setShowMailPopup);
  const setShowThankYou = useStore((state) => state.setShowThankYou);
  const setHasShownMailPopup = useStore((state) => state.setHasShownMailPopup);
  const formRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const lang = useStore((state) => state.lang);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (emailRef.current?.checkValidity()) {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //@ts-ignore
          body: new URLSearchParams(formData).toString(),
        })
          .then(() => {
            setShowMailPopup(false);
            setShowThankYou(true);
            console.log("Form successfully submitted");
          })
          .catch((error) => alert(error));
      }
    } else {
      console.log("invalid");
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={horizontalAnim}
      transition={{ duration: 1.6 }}
      className="w-screen h-screen fixed top-0 z-50"
    >
      <div
        className="absolute text-center w-80 sm:w-120 top-1/2 left-1/2 bg-blue-900 border-4 border-green-400 rounded-lg text-white p-8 mailPopup z-50"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <h2>
          {lang === "br"
            ? "We gaon wa skons moake!"
            : "Frino gaat het theater in!"}
        </h2>
        <span className="sm:mt-8 mt-4 block spaced">
          {lang === "br"
            ? "Un echte Brabo-hop roadstory, tis net unne musical moar dan zonder da zinge en danse en actere. Moar wel in het theater. Bij zijn is klappe vange!"
            : "Dat mag je echt niet missen!"}
        </span>
        <span className="sm:mt-8 mt-4 block spaced">
          {lang === "br"
            ? "Dropt hier oe mail dan komt da heulemaal goed jungske!"
            : "Vul hieronder je emailadres in, dan houden we je op de hoogte."}
        </span>
        {/* @ts-ignore */}
        <form
          name="emailform"
          data-netlify="true"
          ref={formRef}
          method="post"
          onSubmit={handleSubmit}
        >
          <input
            ref={emailRef}
            name="email"
            type="email"
            required
            className="text-black font-frino p-2 mt-6 spaced sm:w-80"
          ></input>
          <input type="hidden" name="form-name" value="emailform" />
          <input name="bot-fiel" type="text" hidden></input>
          <div className="w-full flex flex-col items-center mt-4">
            <button
              type="submit"
              className="font-frino border-white border-2 p-2 rounded-md block text-xl spaced"
            >
              {lang === "br" ? "Kom maar door!" : "Hou mij op de hoogte"}
            </button>

            <div
              onClick={() => {
                setShowMailPopup(false);
                setHasShownMailPopup(true);
              }}
              className="font-frino border-white border-2 p-2 rounded-md block text-xl mt-2 spaced"
            >
              {lang === "br" ? "Laat me met rust gek!" : "Liever niet"}
            </div>
          </div>
        </form>
        <img
          className="absolute bottom-0 w-60 -right-60"
          src="/img/scootmobiel.png"
        />
      </div>
    </motion.div>
  );
}
