import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { flipAnim, horizontalAnim } from "../Animations";
import useStore from "../Store";

export default function ThankYou() {
  const setShowThankYou = useStore((state) => state.setShowThankYou);

  const handleClick = () => {
    setShowThankYou(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("key", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("key", handleClick);
    };
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={flipAnim}
      transition={{ duration: 1.6 }}
      className="w-screen h-screen fixed top-0 "
    >
      <div
        className="absolute text-center w-80 sm:w-120 top-1/2 left-1/2 text-white p-16 flex flex-col items-center bg-pink-400 border-4 border-red-800 rounded-xl"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <h2>Dank! Ge heurt nog van ons!</h2>
        <img src="/img/shareimg.jpg" className="w-60 h-60 mt-8"></img>
      </div>
    </motion.div>
  );
}
