export const circleAnim = {
  initial: {
    clipPath: "circle(0% at 50% 50%)",
  },
  in: {
    clipPath: "circle(100% at 50% 50%)",
  },
  out: {
    clipPath: "circle(0% at 50% 50%)",
  },
};

export const verticalAnim = {
  initial: {
    top: "100vh",
  },
  in: {
    top: 0,
  },
  out: {
    top: "100vh",
  },
};

export const fadeAnim = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

export const horizontalAnim = {
  initial: {
    left: "-160vw",
  },
  in: {
    left: 0,
  },
  out: {
    left: "100vw",
  },
};

export const verticalAnimTop = {
  initial: {
    top: "100vh",
  },
  in: {
    top: 0,
  },
};

export const flipAnim = {
  initial: {
    transform: "scale(0) rotate(900deg)",
  },
  in: {
    transform: "scale(1) rotate(0deg)",
  },
  out: {
    transform: "scale(0) rotate(900deg)",
  },
};
