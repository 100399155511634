import { useState, useEffect, useRef } from "react";

export default function useFrame(func: Function) {
  const animRef = useRef<any>();
  useEffect(() => {
    function animFunc() {
      func();
      animRef.current = requestAnimationFrame(animFunc);
    }
    animRef.current = requestAnimationFrame(animFunc);
    return () => {
      cancelAnimationFrame(animRef.current);
    };
  }, [func]);
}
