import { Sprite } from "@inlet/react-pixi";
import React, { useState, useEffect, useRef } from "react";
import useFrame from "../hooks/useFrame";

export default function MouseParticleManager() {
  const initState: { particles: Particle[] } = { particles: [] };
  const [STATE, setSTATE] = useState({ ...initState });
  const STATEREF = useRef({ ...initState });

  const updateState = (update: { [x: string]: any }) => {
    STATEREF.current = { ...STATEREF.current, ...update };
    setSTATE(STATEREF.current);
  };

  const PARTICLEMINSPEED = 2;
  const PARTICLEMAXSPEED = 6;
  const PARTICLEMINAMT = 4;
  const PARTICLEMAXAMT = 9;
  const PARTICLEMINROTSPEED = -10;
  const PARTICLEMAXROTSPEED = 10;
  const PARTICLESPRITES = ["/img/bier.png", "/img/voetbal.png", "/img/psv.png"];
  const PARTICLELIFETIME = 1000;
  const GRAVITY = 0.2;
  const MINSCALE = 60;
  const MAXSCALE = 120;

  class Particle {
    x: number;
    y: number;
    speed: number;
    speedX: number;
    speedY: number;
    angle: number;
    rotation: number;
    rotSpeed: number;
    scale: number;
    img: number;
    creationTime: number;
    dead: boolean;
    constructor(startX: number, startY: number) {
      this.x = startX;
      this.y = startY;
      this.scale = randomBetweenNumbers(MINSCALE, MAXSCALE);
      this.speed = randomBetweenNumbers(PARTICLEMINSPEED, PARTICLEMAXSPEED);
      this.angle = Math.random() * Math.PI * 2;
      this.speedX = Math.sin(this.angle) * this.speed;
      this.speedY = Math.cos(this.angle) * this.speed;
      this.rotation = Math.random() * Math.PI * 2;
      this.img = Math.floor(Math.random() * PARTICLESPRITES.length);
      this.rotSpeed =
        randomBetweenNumbers(PARTICLEMINROTSPEED, PARTICLEMAXROTSPEED) / 100;
      this.creationTime = performance.now();
      this.dead = false;
    }

    update() {
      this.x += this.speedX;
      this.y += this.speedY;
      this.speedY += GRAVITY;
      this.rotation += this.rotSpeed;
      if (this.y > window.innerHeight) {
        this.dead = true;
      }
    }
  }

  function addParticles(x: number, y: number) {
    const newParticles = [...STATEREF.current.particles];
    for (
      let i = 0;
      i < randomBetweenNumbers(PARTICLEMINAMT, PARTICLEMAXAMT);
      i++
    ) {
      newParticles.push(new Particle(x, y));
    }
    updateState({ particles: newParticles });
  }

  useFrame(() => {
    const newParticles = [...STATEREF.current.particles];
    const deadParticleArr: number[] = [];
    newParticles.forEach((particle, index) => {
      particle.update();
      if (particle.dead) {
        deadParticleArr.push(index);
      }
    });
    deadParticleArr.sort((a, b) => b - a);
    deadParticleArr.forEach((deadIndex) => newParticles.splice(deadIndex, 1));
    updateState({ particles: newParticles });
  });

  useEffect(() => {
    window.addEventListener("click", (e) => addParticles(e.clientX, e.clientY));
    return () => {
      window.removeEventListener("click", (e) =>
        addParticles(e.clientX, e.clientY)
      );
    };
  }, []);
  return (
    <>
      {STATE.particles.map((particle) => {
        return (
          <Sprite
            image={PARTICLESPRITES[particle.img]}
            x={particle.x}
            y={particle.y}
            rotation={particle.rotation}
            anchor={0.5}
            scale={particle.scale / 100}
          />
        );
      })}
    </>
  );
}

function randomBetweenNumbers(low: number, high: number) {
  return low + Math.round(Math.random() * (high - low));
}
