import React from "react";
import { Stage, Sprite } from "@inlet/react-pixi";
import MouseParticleManager from "./MouseParticleManager";

export default function PixiManager() {
  return (
    <div className="fixed w-screen h-screen overflow-hidden z-50 top-0 left-0 pointer-events-none">
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        options={{ backgroundAlpha: 0 }}
      >
        <MouseParticleManager />
      </Stage>
    </div>
  );
}
