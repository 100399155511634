import React from "react";
import useStore from "../Store";

export default function SocialButtons() {
  const setPointer = useStore((state) => state.setPointer);

  return (
    <div className="w-full flex justify-between">
      <a
        href="https://www.instagram.com/frinomusic/"
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
      >
        <img
          src="/img/instagram.png"
          className="sm:w-20 sm:h-20 w-12 h-12 transform duration-500 transition-transform sm:hover:rotate-180"
        />
      </a>
      <a
        target="_blank"
        href="https://www.youtube.com/channel/UCEPkxz-eWwyJAm04L3GlU9A"
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
      >
        <img
          src="/img/youtube.png"
          className="sm:w-20 sm:h-20 w-12 h-12   transform duration-500 transition-transform sm:hover:rotate-180"
        />
      </a>
      <a
        href="mailto:nadinevanpinxteren@gmail.com"
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
      >
        <img
          src="/img/mail.png"
          className="sm:w-20 sm:h-20 w-12 h-12   transform duration-500 transition-transform sm:hover:rotate-180"
        />
      </a>
    </div>
  );
}
