import React from "react";
import useStore from "../Store";

export default function LanguageSelect() {
  const setLang = useStore((state) => state.setLang);
  const lang = useStore((state) => state.lang);
  const setPointer = useStore((state) => state.setPointer);

  return (
    <div className="absolute w-12 sm:w-20 top-3 left-3 flex flex-col z-50">
      <img
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        src="/img/brvlag.png"
        className="w-full mb-2"
        onClick={() => setLang("br")}
        style={{
          border: lang === "br" ? "3px solid red" : "none",
        }}
      />
      <img
        onMouseEnter={() => setPointer(true)}
        onMouseLeave={() => setPointer(false)}
        src="/img/nlvlag.png"
        className="w-full mb-2"
        onClick={() => setLang("nl")}
        style={{
          border: lang === "nl" ? "3px solid red" : "none",
        }}
      />
    </div>
  );
}
